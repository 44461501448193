<template>
    <section class="invoice-add-wrapper">
      <!-- Alert: No item found -->
     
  
      <b-row
       
        class="invoice-add"
      >
  
        <!-- Col: Left (Invoice Container) -->
        <b-col
          cols="12"
          xl="12"
          md="12"
        >
          <b-form @submit.prevent>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
           <b-card-body>
            <b-row>
            <b-col
                            cols="12"
                            lg="3"
                          >
                          <div class="mt-2">
                            <b-form-group
              label="Accept Payment Via"
              label-for="payment-method"
            >
                            <b-form-input
                              
                              type="number"
                              class="mb-2"
                            />
                            </b-form-group>
                        </div>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                          <div class="mt-2">
                            <b-form-group
              label="Accept Payment Via"
              label-for="payment-method"
            >
                            <b-form-input
                              
                              type="number"
                              class="mb-2"
                            />
                            </b-form-group>
                        </div>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                          <div class="mt-2">
                            <b-form-group
              label="Accept Payment Via"
              label-for="payment-method"
            >
                            <b-form-input
                              
                              type="number"
                              class="mb-2"
                            />
                            </b-form-group>
                        </div>
                          </b-col>
            <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
  
        
          <!-- Payment Method -->
          <div class="mt-2">
            <b-form-group
              label="Accept Payment Via"
              label-for="payment-method"
            >
              <v-select
                
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="paymentMethods"
                input-id="payment-method"
                class="payment-selector"
                :clearable="false"
              />
            </b-form-group>
  
            <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
  
         
          </div>
        </b-col>


            <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
  
        
          <!-- Payment Method -->
          <div class="mt-2">
            <b-form-group
              label="Accept Payment Via"
              label-for="payment-method"
            >
              <v-select
                
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="paymentMethods"
                input-id="payment-method"
                class="payment-selector"
                :clearable="false"
              />
            </b-form-group>
  
            <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
  
         
          </div>
        </b-col>
    </b-row>
           </b-card-body>
  
              <!-- Spacer -->
              <hr class="invoice-spacing">
  
              <!-- Invoice Client & Payment Details -->
             \
  
              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <b-row
                    v-for="(item, index) in itemsOptions"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >
  
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
  
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            Item
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            Amount
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            Unit
                          </b-col>
                          
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>
  
                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <label class="d-inline d-lg-none">Item</label>
                            <v-select
                              v-model="item.itemTitle"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="itemsOptions"
                              label="itemTitle"
                              :clearable="false"
                              class="mb-2 item-selector-title"
                              placeholder="Select Item"
                              @input="val => updateItemForm(index, val)"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <label class="d-inline d-lg-none">Amount</label>
                            <b-form-input
                              v-model="item.cost"
                              type="number"
                              class="mb-2"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none">Unit</label>
                            <b-form-input
                              v-model="item.qty"
                              type="number"
                              class="mb-2"
                            />
                          </b-col>
                         
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <label class="d-inline d-lg-none">Description</label>
                            <b-form-textarea
                              v-model="item.description"
                              class="mb-2 mb-lg-0"
                            />
                          </b-col>
                         
                        </b-row>
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        
                          
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemForm"
                >
                  Add Item
                </b-button>
              </b-card-body>
  
              <!-- Invoice Description: Total -->
         
  
              <!-- Spacer -->
              <hr class="invoice-spacing">
  
              <!-- Note -->
           
            </b-card>
          </b-form>
        </b-col>
  
        <!-- Right Col: Card -->
       
      </b-row>
  
      <!-- <invoice-sidebar-send-invoice />
      <invoice-sidebar-add-payment /> -->
    </section>
  </template>
  
  <script>
  import Logo from '@core/layouts/components/Logo.vue'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { heightTransition } from '@core/mixins/ui/transition'
  import Ripple from 'vue-ripple-directive'
  import store from '@/store'
  import router from '@/router'
  import {
    BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, BAlert, BLink, VBToggle,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import flatPickr from 'vue-flatpickr-component'
 
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardBody,
      BButton,
      BCardText,
      BForm,
      BFormGroup,
      BFormInput,
      BInputGroup,
      BInputGroupPrepend,
      BFormTextarea,
      BFormCheckbox,
      BPopover,
      BAlert,
      BLink,
      flatPickr,
      vSelect,
      Logo,
      
    },
    directives: {
      Ripple,
      'b-toggle': VBToggle,
    },
    mixins: [heightTransition],
  
    // Reset Tr Height if data changes
    watch: {
      // eslint-disable-next-line func-names
      'invoiceData.items': function () {
        this.initTrHeight()
      },
    },
    mounted() {
      this.initTrHeight()
    },
    created() {
      window.addEventListener('resize', this.initTrHeight)
    },
    destroyed() {
      window.removeEventListener('resize', this.initTrHeight)
    },
    methods: {
      addNewItemInItemForm() {
        this.$refs.form.style.overflow = 'hidden'
        this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))
  
        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
          setTimeout(() => {
            this.$refs.form.style.overflow = null
          }, 350)
        })
      },
      removeItem(index) {
        this.invoiceData.items.splice(index, 1)
        this.trTrimHeight(this.$refs.row[0].offsetHeight)
      },
      initTrHeight() {
        this.trSetHeight(null)
        this.$nextTick(() => {
          this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
        })
      },
    },
    setup() {
      const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'
  
    //   // Register module
    //   if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
  
    //   // UnRegister on leave
    //   onUnmounted(() => {
    //     if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    //   })
  
      const invoiceData = ref(null)
      const paymentDetails = ref({})
  
     
  
      const itemFormBlankItem = {
        item: null,
        cost: 0,
        qty: 0,
        description: '',
      }
  
      const itemsOptions = [
        {
          itemTitle: 'App Design',
          cost: 24,
          qty: 1,
          description: 'Designed UI kit & app pages.',
        },
        {
          itemTitle: 'App Customization',
          cost: 26,
          qty: 1,
          description: 'Customization & Bug Fixes.',
        },
        {
          itemTitle: 'ABC Template',
          cost: 28,
          qty: 1,
          description: 'Bootstrap 4 admin template.',
        },
        {
          itemTitle: 'App Development',
          cost: 32,
          qty: 1,
          description: 'Native App Development.',
        },
      ]
  
      const updateItemForm = (index, val) => {
        const { cost, qty, description } = val
        invoiceData.value.items[index].cost = cost
        invoiceData.value.items[index].qty = qty
        invoiceData.value.items[index].description = description
      }
  
      const paymentMethods = [
        'Bank Account',
        'PayPal',
        'UPI Transfer',
      ]
  
      return {
        invoiceData,
        itemsOptions,
        updateItemForm,
        itemFormBlankItem,
        paymentMethods,
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
  
  <style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  
  .form-item-section {
  background-color:$product-details-bg;
  }
  
  .form-item-action-col {
    width: 27px;
  }
  
  .repeater-form {
    // overflow: hidden;
    transition: .35s height;
  }
  
  .v-select {
    &.item-selector-title,
    &.payment-selector {
      background-color: #fff;
  
      .dark-layout & {
        background-color: unset;
      }
    }
  }
  
  .dark-layout {
    .form-item-section {
      background-color: $theme-dark-body-bg;
  
      .row .border {
        background-color: $theme-dark-card-bg;
      }
  
    }
  }
  </style>
  